// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-startup-solutions-js": () => import("./../../../src/pages/services/startup-solutions.js" /* webpackChunkName: "component---src-pages-services-startup-solutions-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-landing-pages-index-js": () => import("./../../../src/pages/work/landing-pages/index.js" /* webpackChunkName: "component---src-pages-work-landing-pages-index-js" */),
  "component---src-pages-work-landing-pages-wallet-optimizer-js": () => import("./../../../src/pages/work/landing-pages/wallet-optimizer.js" /* webpackChunkName: "component---src-pages-work-landing-pages-wallet-optimizer-js" */),
  "component---src-pages-work-mobile-applications-amori-js": () => import("./../../../src/pages/work/mobile-applications/amori.js" /* webpackChunkName: "component---src-pages-work-mobile-applications-amori-js" */),
  "component---src-pages-work-mobile-applications-foodel-js": () => import("./../../../src/pages/work/mobile-applications/foodel.js" /* webpackChunkName: "component---src-pages-work-mobile-applications-foodel-js" */),
  "component---src-pages-work-mobile-applications-index-js": () => import("./../../../src/pages/work/mobile-applications/index.js" /* webpackChunkName: "component---src-pages-work-mobile-applications-index-js" */),
  "component---src-pages-work-mobile-applications-wallet-optimizer-js": () => import("./../../../src/pages/work/mobile-applications/wallet-optimizer.js" /* webpackChunkName: "component---src-pages-work-mobile-applications-wallet-optimizer-js" */),
  "component---src-pages-work-web-applications-devlink-js": () => import("./../../../src/pages/work/web-applications/devlink.js" /* webpackChunkName: "component---src-pages-work-web-applications-devlink-js" */),
  "component---src-pages-work-web-applications-graphic-color-js": () => import("./../../../src/pages/work/web-applications/graphic-color.js" /* webpackChunkName: "component---src-pages-work-web-applications-graphic-color-js" */),
  "component---src-pages-work-web-applications-index-js": () => import("./../../../src/pages/work/web-applications/index.js" /* webpackChunkName: "component---src-pages-work-web-applications-index-js" */),
  "component---src-pages-work-web-applications-wfh-lister-js": () => import("./../../../src/pages/work/web-applications/wfh-lister.js" /* webpackChunkName: "component---src-pages-work-web-applications-wfh-lister-js" */),
  "component---src-pages-work-websites-beachhop-js": () => import("./../../../src/pages/work/websites/beachhop.js" /* webpackChunkName: "component---src-pages-work-websites-beachhop-js" */),
  "component---src-pages-work-websites-foodel-js": () => import("./../../../src/pages/work/websites/foodel.js" /* webpackChunkName: "component---src-pages-work-websites-foodel-js" */),
  "component---src-pages-work-websites-index-js": () => import("./../../../src/pages/work/websites/index.js" /* webpackChunkName: "component---src-pages-work-websites-index-js" */),
  "component---src-pages-work-websites-tackis-js": () => import("./../../../src/pages/work/websites/tackis.js" /* webpackChunkName: "component---src-pages-work-websites-tackis-js" */)
}

